:root {
  --blue-color: #007bff;
  --orange-color: #ffb400;
  --gradient: linear-gradient(135deg, var(--blue-color), var(--orange-color));
  --bg-white: #ffffff;
  --card-shadow: rgba(0, 0, 0, 0.2);
}

.single-phase-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  perspective: 1000px;
}

/* Phase Section and Overlay */
.phase-section {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background 1s ease-in-out;
  transform-style: preserve-3d;
}

.phase-overlay {
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent);
  opacity: 0.5;
  pointer-events: none;
  animation: floatOverlay 20s ease-in-out infinite;
  z-index: 1;
}

@keyframes floatOverlay {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(20px, 20px) rotate(45deg); }
}

/* Phase Content with 3D Effect */
.phase-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  padding: 2.5rem;
  background: rgba(255, 255, 255, 0.95);
  color: #333;
  border-radius: 20px;
  box-shadow: 0 15px 30px var(--card-shadow);
  text-align: center;
  opacity: 0;
  transform: translateY(20px) rotateX(10deg);
  animation: contentFadeIn 1.5s forwards;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.phase-content:hover {
  transform: translateY(-10px) rotateX(0deg);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
}

@keyframes contentFadeIn {
  to { opacity: 1; transform: translateY(0) rotateX(0deg); }
}

/* Icon Styling */
.icon {
  font-size: 5rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  padding: 1rem;
  border-radius: 50%;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon:hover {
  transform: scale(1.1) rotate(15deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Text Styling */
.phase-content h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  background: linear-gradient(135deg, #007bff, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phase-content p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #555;
}

/* Navigation Dots */
.phase-navigation {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
  z-index: 3;
}

.nav-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid transparent;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.nav-dot.active {
  transform: scale(1.4);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.nav-dot:hover {
  transform: scale(1.2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

/* Unique Backgrounds for Each Phase */
.phase-0 {
  background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1), var(--bg-white)),
              radial-gradient(circle at bottom right, rgba(0, 191, 255, 0.1), transparent);
  animation: bgAnim0 15s ease-in-out infinite;
}

.phase-1 {
  background: radial-gradient(circle at top left, rgba(255, 111, 97, 0.1), transparent),
              radial-gradient(circle at bottom right, rgba(255, 180, 0, 0.1), var(--bg-white));
  animation: bgAnim1 20s ease-in-out infinite;
}

.phase-2 {
  background: radial-gradient(circle at top right, rgba(106, 17, 203, 0.1), transparent),
              radial-gradient(circle at bottom left, rgba(37, 117, 252, 0.1), var(--bg-white));
  animation: bgAnim2 25s ease-in-out infinite;
}

.phase-3 {
  background: radial-gradient(circle at center, rgba(255, 65, 108, 0.1), transparent),
              radial-gradient(circle at center, rgba(255, 75, 43, 0.1), var(--bg-white));
  animation: bgAnim3 30s ease-in-out infinite;
}

@keyframes bgAnim0 {
  0%, 100% { background-position: 0% 0%, 100% 100%; }
  50% { background-position: 100% 0%, 0% 100%; }
}

@keyframes bgAnim1 {
  0%, 100% { background-position: 0% 100%, 100% 0%; }
  50% { background-position: 100% 100%, 0% 0%; }
}

@keyframes bgAnim2 {
  0%, 100% { background-position: 50% 50%, 50% 50%; }
  50% { background-position: 75% 25%, 25% 75%; }
}

@keyframes bgAnim3 {
  0%, 100% { background-position: 0% 0%, 100% 100%; }
  50% { background-position: 100% 0%, 0% 100%; }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .phase-content { padding: 1.5rem; }
  .icon { font-size: 4rem; }
  .phase-content h3 { font-size: 2rem; }
  .phase-content p { font-size: 1rem; }
}