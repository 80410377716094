/* Footer Section */
.footer {
   
    padding: 3rem 2rem;
    text-align: center;
    color: #333;
    font-family: 'Poppins', sans-serif;
    border-top: 2px solid #f0f0f0;
    position: relative;
    overflow-x: hidden;
  }
  
  /* Footer Container */
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 2rem;
  }
  
  /* Brand Section */
  .footer-logo {
    flex: 1;
    text-align: left;
  }
  
  .brand-name {
    font-size: 2.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #007bff, #ff7eb3, #ffb400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .footer-logo p {
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
  }
  
  /* Quick Links */
  .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1.5rem;
  }
  
  .footer-links li {
    display: inline;
  }
  
  .footer-links a {
    text-decoration: none;
    font-size: 1rem;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #ff7eb3;
  }
  
  /* Social Media Section */
  .footer-social {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
  
  .footer-social a {
    font-size: 1.5rem;
    color: #007bff;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: #ff7eb3;
    transform: scale(1.2);
  }
  
  /* Bottom Section */
  .footer-bottom {
    border-top: 1px solid #f0f0f0;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 0.9rem;
    color: #666;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .policies a {
    text-decoration: none;
    margin-left: 1rem;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .policies a:hover {
    color: #ff7eb3;
  }
  
  /* Subtle Background Shadow */
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 123, 255, 0.05), transparent);
    z-index: -1;
    opacity: 0.7;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
      gap: 1.5rem;
    }
  
    .footer-bottom {
      flex-direction: column;
      gap: 1rem;
    }
  
    .footer-social {
      justify-content: center;
    }
  }
  