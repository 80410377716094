/* FoundersPage.css */
.founders-container {
  background: linear-gradient(45deg, #ffffff, #f8fafc, #ffffff);
  min-height: 100vh;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Metallic Dust Effects */
.metallic-dust {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

/* Floating Shapes */
.floating-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.floating-shapes div {
  position: absolute;
  opacity: 0.1;
  animation: float 25s infinite linear;
}

@keyframes float {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-40vh) rotate(180deg); }
  100% { transform: translateY(0) rotate(360deg); }
}

.shape-1 { 
  left: 10%; 
  top: 20%; 
  width: 80px; 
  height: 80px; 
  background: linear-gradient(45deg, #cffafe, #e0f2fe); 
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; 
}

.shape-2 { 
  right: 15%; 
  top: 60%; 
  width: 120px; 
  height: 120px; 
  background: linear-gradient(45deg, #f0fdfa, #ccfbf1); 
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); 
}

.shape-3 { 
  left: 30%; 
  top: 80%; 
  width: 100px; 
  height: 100px; 
  background: linear-gradient(45deg, #f5f3ff, #ede9fe); 
  border-radius: 50%; 
}

/* Sparkle Animation */
@keyframes sparkle {
  0% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0); }
}

.sparkle {
  position: absolute;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 10%, transparent 70%);
  pointer-events: none;
  animation: sparkle 1.5s infinite;
}

/* Existing Styles */
.header-section {
  text-align: center;
  margin-bottom: 6rem;
  position: relative;
  z-index: 2;
}

.gradient-text {
  font-size: 4.5rem;
  font-weight: 800;
  background: linear-gradient(45deg, #4ade80, #3b82f6, #f59e0b);
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 1.5rem;
  letter-spacing: -0.05em;
  text-shadow: 0 4px 10px rgba(0,0,0,0.1);
  animation: gradient-flow 8s ease infinite;
}

@keyframes gradient-flow {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

.subtitle {
  color: #64748b;
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.highlight-text {
  background: linear-gradient(90deg, #4ade80, #3b82f6);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}

.founders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.founder-card {
  background: white;
  border-radius: 2rem;
  padding: 1.5rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.founder-card:hover {
  box-shadow: 0 15px 40px rgba(0,0,0,0.12);
}

.card-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle at 50% 0%, rgba(59,130,246,0.1), transparent 60%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.founder-card:hover .card-glow {
  opacity: 1;
}

.founder-image {
  width: 100%;
  height: 400px;
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
}

.image-shimmer {
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, transparent 40%, rgba(255,255,255,0.3) 50%, transparent 60%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.founder-image:hover .image-shimmer {
  opacity: 1;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% { background-position: -100% 0 }
  100% { background-position: 200% 0 }
}

.social-overlay {
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  background: linear-gradient(transparent, rgba(0,0,0,0.1));
  transition: all 0.3s ease;
}

.social-icon {
  color: white;
  font-size: 1.5rem;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  transition: all 0.3s ease;
}

.founder-info {
  text-align: center;
  padding: 1rem;
}

.founder-name {
  font-size: 1.75rem;
  font-weight: 700;
  color: #0f172a;
  margin-bottom: 0.5rem;
}

.founder-role {
  color: #64748b;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.accent-line {
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, #4ade80, #3b82f6);
  margin: 0 auto;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.founder-card:hover .accent-line {
  transform: scaleX(1.5);
}