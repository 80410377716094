/* Hero Container */
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: visible;
  padding: 0 2rem;
  position: relative;
}

/* Hero Text Content */
.hero-text-content {
  z-index: 10;
  text-align: center;
  max-width: 800px;
  animation: fadeIn 1.2s ease;
}

.hero-text-content h1 {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: #333;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #007bff, #00d4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text-content h1 span {
  background: linear-gradient(90deg, #00d4ff, #00b4a7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text-content p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
  line-height: 1.6;
  animation: fadeInUp 1.2s ease;
}

/* Hero Action Button */
.hero-action-button {
  background: linear-gradient(135deg, #007bff, #00b4a7);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 25px rgba(0, 123, 255, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.hero-action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 15px 40px rgba(0, 123, 255, 0.4);
}

/* Hero Background Elements */
.hero-background-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.background-circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
  animation: float 10s infinite ease-in-out;
}

.blue-circle {
  width: 300px;
  height: 300px;
  background: #007bff;
  top: 10%;
  left: 10%;
}

.green-circle {
  width: 500px;
  height: 500px;
  background: #00b4a7;
  top: 60%;
  right: 20%;
}

.yellow-circle {
  width: 400px;
  height: 400px;
  background: #00d4ff;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-text-content h1 {
    font-size: 2.5rem;
  }

  .hero-text-content p {
    font-size: 1rem;
  }

  .hero-action-button {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }

  .blue-circle {
    width: 200px;
    height: 200px;
    top: 30%;
    left: 10%;
  }

  .green-circle {
    width: 300px;
    height: 300px;
    top: 70%;
    right: 10%;
  }

  .yellow-circle {
    width: 250px;
    height: 250px;
    bottom: 5%;
    left: 45%;
  }
}
