/* RoadmapSection.css */
.roadmap-section {
  padding: 8rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #f8f9ff, #ffffff);
}

.roadmap-section::before,
.roadmap-section::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(45deg, rgba(0,123,255,0.08), rgba(255,126,179,0.08));
  border-radius: 50%;
  filter: blur(100px);
  z-index: 0;
  animation: float 25s infinite alternate ease-in-out;
}

.roadmap-section::before {
  top: -200px;
  left: -200px;
}

.roadmap-section::after {
  bottom: -200px;
  right: -200px;
  background: linear-gradient(45deg, rgba(255,126,179,0.08), rgba(255,180,0,0.08));
}

@keyframes float {
  0% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(100px, 150px) scale(1.1); }
  100% { transform: translate(-100px, -50px) scale(0.9); }
}

.roadmap-heading {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 5rem;
  position: relative;
  display: inline-block;
  background: linear-gradient(90deg, #007bff, #00d4ff, #ff7eb3, #ffb400);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 12s ease infinite;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.roadmap-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.roadmap-step {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 25px;
  padding: 2.5rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 300px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
  animation-delay: calc(var(--index) * 0.15s);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.roadmap-step:hover {
  transform: translateY(-10px) scale(1.03) rotateZ(-1deg);
  box-shadow: 0 25px 50px rgba(0, 123, 255, 0.15),
    0 0 40px rgba(0, 123, 255, 0.1);
}

.step-circle {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #007bff, #ff7eb3);
  color: #fff;
  font-size: 1.75rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  box-shadow: 0 15px 30px rgba(0, 123, 255, 0.2);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.step-circle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    transparent 20%, 
    rgba(255, 255, 255, 0.3) 50%,
    transparent 80%);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% { transform: translateX(-150%) skew(20deg); }
  60% { transform: translateX(150%) skew(20deg); }
  100% { transform: translateX(150%) skew(20deg); }
}

.roadmap-step:hover .step-circle {
  transform: scale(1.1);
  box-shadow: 0 20px 40px rgba(0, 123, 255, 0.3);
}

.step-content h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #2d3436;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #007bff, #ff7eb3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step-content p {
  font-size: 1.1rem;
  color: #636e72;
  line-height: 1.7;
  font-weight: 500;
}

@media (max-width: 768px) {
  .roadmap-section {
    padding: 5rem 1.5rem;
  }
  
  .roadmap-heading {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
  
  .roadmap-step {
    width: 100%;
    max-width: 400px;
  }
}/* RoadmapSection.css */
.roadmap-section {
  padding: 8rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #f8f9ff, #ffffff);
}

.roadmap-section::before,
.roadmap-section::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(45deg, rgba(0,123,255,0.08), rgba(255,126,179,0.08));
  border-radius: 50%;
  filter: blur(100px);
  z-index: 0;
  animation: float 25s infinite alternate ease-in-out;
}

.roadmap-section::before {
  top: -200px;
  left: -200px;
}

.roadmap-section::after {
  bottom: -200px;
  right: -200px;
  background: linear-gradient(45deg, rgba(255,126,179,0.08), rgba(255,180,0,0.08));
}

@keyframes float {
  0% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(100px, 150px) scale(1.1); }
  100% { transform: translate(-100px, -50px) scale(0.9); }
}

.roadmap-heading {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 5rem;
  position: relative;
  display: inline-block;
  background: linear-gradient(90deg, #007bff, #00d4ff, #ff7eb3, #ffb400);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 12s ease infinite;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.roadmap-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.roadmap-step {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 25px;
  padding: 2.5rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 300px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
  animation-delay: calc(var(--index) * 0.15s);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.roadmap-step:hover {
  transform: translateY(-10px) scale(1.03) rotateZ(-1deg);
  box-shadow: 0 25px 50px rgba(0, 123, 255, 0.15),
    0 0 40px rgba(0, 123, 255, 0.1);
}

.step-circle {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #007bff, #ff7eb3);
  color: #fff;
  font-size: 1.75rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  box-shadow: 0 15px 30px rgba(0, 123, 255, 0.2);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.step-circle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    transparent 20%, 
    rgba(255, 255, 255, 0.3) 50%,
    transparent 80%);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% { transform: translateX(-150%) skew(20deg); }
  60% { transform: translateX(150%) skew(20deg); }
  100% { transform: translateX(150%) skew(20deg); }
}

.roadmap-step:hover .step-circle {
  transform: scale(1.1);
  box-shadow: 0 20px 40px rgba(0, 123, 255, 0.3);
}

.step-content h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #2d3436;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #007bff, #ff7eb3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step-content p {
  font-size: 1.1rem;
  color: #636e72;
  line-height: 1.7;
  font-weight: 500;
}

@media (max-width: 768px) {
  .roadmap-section {
    padding: 5rem 1.5rem;
  }
  
  .roadmap-heading {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
  
  .roadmap-step {
    width: 100%;
    max-width: 400px;
  }
}