/* Info Section Container */
.info-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6rem 2rem;
    overflow-x: hidden;
    position: relative;
    overflow: hidden;
  }
  
  /* Info Wrapper */
  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    gap: 7rem;
    position: relative;
    z-index: 10;
  }
  
  /* Image Container */
  .info-image-container {
    flex: 1;
    position: relative;
    max-width: 500px;
    animation: fadeInRight 1.2s ease;
  }
  
  .info-image-container img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .info-image-container img:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  }
  
  .info-gradient-shape {
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, rgba(0, 123, 255, 0.15), rgba(255, 100, 145, 0.15));
    border-radius: 50%;
    z-index: -1;
    animation: float 20s infinite ease-in-out;
  }
  
  /* Content Section */
  .info-content {
    flex: 1;
    max-width: 600px;
    animation: fadeInLeft 1.2s ease;
  }
  
  .info-content h2 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.3;
    color: #333;
    margin-bottom: 1.5rem;
    background: linear-gradient(90deg, #007bff, #00d4ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .info-content h2 span {
    background: linear-gradient(90deg, #ff7eb3, #ffb400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .info-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .info-cta {
    display: flex;
    gap: 1rem;
  }
  
  /* Buttons */
  .info-button {
    background: linear-gradient(135deg, #007bff, #ff7eb3);
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 10px 25px rgba(0, 123, 255, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
  }
  
  .info-button:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 40px rgba(0, 123, 255, 0.4);
  }
  
  .info-outline-button {
    background: transparent;
    color: #007bff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.8rem 2rem;
    border: 2px solid #007bff;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: nonei ;
  }
  
  .info-outline-button:hover {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
    transform: scale(1.1);
  }
  
  /* Animations */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .info-wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .info-content {
      max-width: 100%;
    }
  
    .info-image-container {
      max-width: 100%;
    }
  }
  